<!-- @format -->

<content-form>
  <div is="{state.phase}"
    Fields={Fields}
    onConfirm={onConfirm}
    onSubmit={onSubmit}
    onBack={onBack}
    params={state.params}
    errors={state.errors}
    prefix={props.prefix}
  ></div>
  <style>
  .error {
    color:red;
  }
  </style>
  <script>
    import di from "dependeny-injection";
    import contentFormInput from "./content-form-input.riot";
    import contentFormConfirm from "./content-form-confirm.riot";
    import contentFormComplete from "./content-form-complete.riot";

    export default {
      components: {
        'content-form-input': contentFormInput,
        'content-form-confirm': contentFormConfirm,
        'content-form-complete': contentFormComplete
      },
      Fields: {
        'agreement'       :{name:'同意する', type:'checkbox', required: true},
        'company_name'    :{name:'会社名', type:'text', required: true},
        'department_name' :{name:'部署名', type:'text', required: false},
        'name'            :{name:'お名前', type:'text', required: true},
        'tel'             :{name:'電話', type:'text', required: true},
        'email'           :{name:'メール', type:'text', required: true},
        'budget'          :{name:'ご予算', type:'text', required: false, items: ['30万円', '50万円', '100万円', '200万円', '別途相談']},
        'volume'          :{name:'作成記事数', type:'text', required: false, items: ['10記事', '30記事', '50記事', '100記事', '別途相談']},
        'genre'           :{name:'ジャンル', type:'text', required: false, items: ['健康','美容','ファッション/アパレル/装飾品','求人/転職','不動産/賃貸','医療','医療/福祉','住宅/生活','住宅関連','生活/暮らし','冠婚葬祭/暮らしのマナー','教育','資格/習い事','士業','金融','ビジネス/オフィス','IT・通信関連','自動車関連','旅行関連','趣味/娯楽','恋愛/占い','美術/芸術','メディア','癒し','地名/人名','グルメ/食べ物','道具','サブカル','保険','通信販売','美容（男性）','お悩み','イベント','その他']},
        'url'             :{name:'サイトURL', type:'text', required: false},
        'details'         :{name:'詳細内容', type:'textarea', required: true},
      },
      state: {
        params: {},
        errors: {},
        phase: 'content-form-input'
      },
      onBeforeMount(props, state) {
        this.Api = di.createApi();
        console.log(this.state);
      },
      onBack(e){
        this.update({ phase: 'content-form-input',errors: {} });
      },
      async onConfirm(params) {
        try {
          await this.Api.request('validate', '', params);
          this.update({ phase: 'content-form-confirm', params: params});
        } catch (e) {
          if (e.response.message == "invalid") {
            this.update({ errors: e.response.data });
          }
        }
      },
      async onSubmit(e) {
        try {
          await this.Api.request('post', '', this.state.params);
          this.update({ phase: 'content-form-complete' });
        } catch (e) {
          alert(e.response.message)
        }
      },
    };
  </script>
</content-form>
