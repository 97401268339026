<!-- @format -->

<content-form-confirm>
  <section class="contact">
    <form>
      <div class="contact-container">
       <div class="contact-block">
         <section class="contact-item">
           <label class="contact-label" for="label-corp">会社名</label>
           <div class="contact-box">
             <p>{props.params['company_name']}</p>
           </div>
         </section>
         <section class="contact-item">
           <label class="contact-label" for="label-division">部署名</label>
           <div class="contact-box">
             <p>{props.params['department_name']}</p>
           </div>
         </section>
         <section class="contact-item">
           <label class="contact-label" for="label-name">お名前</label>
           <div class="contact-box">
             <p>{props.params['name']}</p>
           </div>
         </section>

         <section class="contact-item">
           <label class="contact-label" for="label-articles">作成記事数</label>
           <div class="contact-box">
            <p>{props.params['volume']}</p>
           </div>
         </section>
       </div>
       <div class="contact-block">
         <section class="contact-item">
           <label class="contact-label" for="label-tel">電話</label>
           <div class="contact-box">
            <p>{props.params['tel']}</p>
           </div>
         </section>

         <section class="contact-item">
           <label class="contact-label" for="label-mail">メール</label>
           <div class="contact-box">
            <p>{props.params['email']}</p>
           </div>
         </section>

        <section class="contact-item">
           <label class="contact-label" for="label-budget">ご予算</label>
           <div class="contact-box">
            <p>{props.params['budget']}</p>
           </div>
         </section>


        <section class="contact-item">
           <label class="contact-label" for="label-genre">ジャンル</label>
           <div class="contact-box">
            <p>{props.params['genre']}</p>
           </div>
         </section>

       </div>
         
       <div class="contact-block wide">
         <section class="contact-item wide">
           <label class="contact-label" for="label-url">サイトURL</label>
           <div class="contact-box">
            <p>{props.params['url']}</p>
           </div>
         </section>

       </div>
       <div class="contact-block wide">
         <section class="contact-item">
           <label class="contact-label" for="label-detail">詳細内容</label>
           <div class="contact-box">
            <pre>{props.params['details']}</pre>
           </div>
         </section>

       </div>  
      </div>

     <div class="contact-submit">
       <div class="contact-submit-box">
         <button
          type="button"
          class=""
          onclick="{ e => props.onBack(e) }"
         ><span class="text-grad01">戻る</span></button>
       </div>

       <div class="contact-submit-box">
         <button
          type="button"
          class="contact-submit-btn js-contact-submit-btn"
          onclick="{ e => props.onSubmit(e) }"
         ><span class="text-grad01">お問合せ・お見積り</span></button>
       </div>
     </div>
   </form>
  </section>


  <script>
    import di from "dependeny-injection";

    export default {
    };
  </script>
</content-form-confirm>
