class AppError extends Error {
    constructor(response, ...params) {
      super(...params)
 
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, AppError)
      }
  
      this.name = 'AppError'
      this.response = response
    }
  }

  export default AppError;