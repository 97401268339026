<!-- @format -->

<content-form-input>
  <style>
    input.input-text.error {
      border-color: #e60057;
    }
    textarea.input-textarea.error {
      border-color: #e60057;
    }
    p.error{
      font-weight: bold;
      color: #e60057;
    }
    select option:first-child{ display: none;}


  </style>
  <section class="contact">
    <form>
      <div class="contact-container">
       <div class="contact-block">
         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-corp">会社名</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('company_name').type}">{getStatus('company_name').label}</span>
             <input
              type="text"
              name="company_name"
              value="{props.params['company_name']}"
              required={props.Fields['company_name'].required}
              class="input-text input-full {getError('company_name')}"
              id="{props.prefix}-label-corp"
              placeholder="例) CROCO株式会社"
             >
             <p if="{ props.errors['company_name'] }" class="error">{ props.errors['company_name'] }</p>
           </div>
         </section>
         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-division">部署名</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('department_name').type}">{getStatus('department_name').label}</span>
             <input
              type="text"
              name="department_name"
              value="{props.params['department_name']}"
              required={props.Fields['department_name'].required}
              class="input-text input-full {getError('department_name')}"
              id="{props.prefix}-label-division"
              placeholder="例) マーケティング部"
             >
             <p if="{ props.errors['department_name'] }" class="error">{ props.errors['department_name'] }</p>
           </div>
         </section>
         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-name">お名前</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('name').type}">{getStatus('name').label}</span>
             <input
              type="text"
              name="name"
              value="{props.params['name']}"
              required={props.Fields['name'].required}
              class="input-text input-full {getError('name')}"
              id="{props.prefix}-label-name"
              placeholder="例) クロコ太郎"
             >
             <p if="{ props.errors['name'] }" class="error">{ props.errors['name'] }</p>
           </div>
         </section>


         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-articles">作成記事数</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('volume').type}">{getStatus('volume').label}</span>
               <select name="volume" class="input-select input-full" id="{props.prefix}-label-articles">
               <option value="">選択してください</option>
               <option value="{val}" each={val in props.Fields['volume'].items} selected={getSelected('volume', val)}>{val}</option>
               </select>
           </div>
         </section>
       </div>
       <div class="contact-block">
         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-tel">電話</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('tel').type}">{getStatus('tel').label}</span>
             <input
              type="text"
              name="tel"
              value="{props.params['tel']}"
              required={props.Fields['tel'].required}
              class="input-text input-full {getError('tel')}"
              id="{props.prefix}-label-tel"
              placeholder="例) 03-4405-9235"
             >
             <p if="{ props.errors['tel'] }" class="error">{ props.errors['tel'] }</p>
           </div>
         </section>

         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-mail">メール</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('email').type}">{getStatus('email').label}</span>
             <input
              type="text"
              name="email"
              value="{props.params['email']}"
              required={props.Fields['email'].required}
              class="input-text input-full {getError('email')}"
              id="{props.prefix}-label-mail"
              placeholder="例) croco@co.jp"
             >
             <p if="{ props.errors['email'] }" class="error">{ props.errors['email'] }</p>
           </div>
         </section>

        <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-budget">ご予算</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('budget').type}">{getStatus('budget').label}</span>
               <select name="budget" class="input-select input-full" id="{props.prefix}-label-budget">
               <option value="">選択してください</option>
               <option value="{val}" each={val in props.Fields['budget'].items} selected={getSelected('budget', val)}>{val}</option>
               </select>
           </div>
         </section>


        <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-genre">ジャンル</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('genre').type}">{getStatus('genre').label}</span>
               <select name="genre" class="input-select input-full" id="{props.prefix}-label-genre">
               <option value="">選択してください</option>
               <option value="{val}" each={val in props.Fields['genre'].items} selected={getSelected('genre', val)}>{val}</option>
               </select>
           </div>
         </section>

       </div>
         
       <div class="contact-block wide">
         <section class="contact-item wide">
           <label class="contact-label" for="{props.prefix}-label-url">サイトURL</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('url').type}">{getStatus('url').label}</span>
             <input
              type="text"
              name="url"
              value="{props.params['url']}"
              required={props.Fields['url'].required}
              class="input-text input-full {getError('url')}"
              id="{props.prefix}-label-url"
              placeholder="https://example.com"
             >
             <p if="{ props.errors['url'] }" class="error">{ props.errors['url'] }</p>
           </div>
         </section>

       </div>
       <div class="contact-block wide">
         <section class="contact-item">
           <label class="contact-label" for="{props.prefix}-label-detail">詳細内容</label>
           <div class="contact-box">
             <span class="contact-status contact-status-{getStatus('details').type}">{getStatus('details').label}</span>
             <textarea
              type="text"
              name="details"
              required={props.Fields['details'].required}
              class="input-textarea input-full {getError('details')}"
              id="{props.prefix}-label-detail"
              rows="4"
              cols="40"
              placeholder="目標KWD・セッション数、課題感、ご要望（商談時にSEOコンサルタントの同席希望）などご記入ください"
             >{props.params['details']}</textarea>
             <p if="{ props.errors['details'] }" class="error">{ props.errors['details'] }</p>
           </div>
         </section>

       </div>  
      </div>

     <div class="contact-agreement">
       <p class="contact-agreement-text">
         <a href="https://cro-co.co.jp/privacypolicy/agreement/" target="_blank">「個人情報の取扱いについて」</a>をお読みいただき、同意いただける場合は、<span class="is-pc-inline"><br></span>「個人情報の取扱いに同意します」にチェックを入れて送信をお願いいたします。
       </p>
       <div class="contact-agreement-check">
         <input type="checkbox" name="agreement" value="on" class="input-checkbox" id="{props.prefix}-lb-agreement" onchange={e=>checkAgreement(e)} checked={getChecked('agreement')}><label for="{props.prefix}-lb-agreement"> 個人情報の取扱いに同意します</label>
       </div>
     </div>

     <div class="contact-submit">
       <div class="contact-submit-box">
         <button
          type="button"
          class="contact-submit-btn js-contact-submit-btn"
          onclick="{ e => onConfirm(e) }"
          disabled
         ><span class="text-grad01">お問合せ・お見積り</span></button>
         <figure class="contact-submit-illust"><img src="img/character01.png" alt="お問合せ・お見積り"></figure>
       </div>
     </div>
   </form>
  </section>

  <p class="cv-tips">お問い合わせ後に当社担当から<i class="is-sp-inline"><br></i>連絡を差し上げます。</p>

  <script>
    import di from "dependeny-injection";

    export default {
      getChecked(name) {
        if (this.props.params[name] === "on") return true;
        return false;
      },
      getSelected(name, val){
        if (this.props.params[name] === val) return true;
        return false;
      },
      getError(name) {
        if (this.props.errors[name]) return 'error';
        return '';
      },
      getStatus(name) {
        if (this.props.Fields[name].required) {
          return {
            label: '必須',
            type: 'required'//complete
          };
        }
        return {
          label: '任意',
          type: 'option'
        };
      },
      checkAgreement(e) {
        this.$('button.contact-submit-btn').disabled = !e.target.checked;
      },
      onConfirm(e) {
        const params = {};
        this.$$('input.error').forEach(item=>item.classList.remove('error'));
        for (const key of Object.keys(this.props.Fields)) {
          params[key] = this.$(`[name=${key}]`).value;
        }
        this.props.onConfirm(params);
      },
      onMounted(){
        this.$('button.contact-submit-btn').disabled = (this.props.params['agreement'] === 'on')?false:true;
      }
    };
  </script>
</content-form-input>
