import Emitter from 'es6-event-emitter';
import qs      from 'qs';
import              'whatwg-fetch';
import AppError from './app-error';

/**
 * サーバー同期（ajax）
 *
 * @package     content
 * @author      Kenichi Kashiwagi <pod@cro-co.co.jp>
 */
class ApiBase extends Emitter {
    /**
     * コンストラクタ
     *
     * @access public
     * @return void
     */
    constructor() {
        super();

        this.JSON_TYPE = "application/json";
    }

    apiPost (url, data, jsonmode = false) {
        const request = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
        };
        if (jsonmode) {
            request.body = JSON.stringify(data);
            request.headers = {
                'Content-Type': 'application/json'
            }
        } else if (data instanceof FormData) {
            request.body = data;
            //request.headersは勝手にセットしてくれる
        } else if (data instanceof Object || data instanceof Array) {
            request.body = qs.stringify(data)
            request.headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        return fetch(url, request).then(res => {
            if (!res.ok) {
                throw new Error('invalid response: ' + res.status);
            }
            return res.json();
        }).then(json => {
            if (!json.success) {
                throw new AppError(json, json.message);
            }
            return json.data;
        });
    }

}

export default ApiBase;