import ApiBase from '@model/api-base';
import di from 'dependeny-injection'

/**
 * menu api
 *
 * @package     content.biz-samurai.com
 * @author      Kenichi Kashiwagi <pod@cro-co.co.jp>
 */
class Api extends ApiBase {
  constructor() {
    super();
  }
  request(name, action, data={}) {
    if (action !== null && action !== "") {
      action = '/' + action
    }
    return this.apiPost(`https://dev-alb.ninja-systems.com/lambda/content-bizsam/api/${name}${action}`, data);
  }
  event(name, action, data) {
    this.trigger(`${name}:${action}`, data);
  }
}

export default Api;
