/* コアライブラリ */
import 'riot';
import Router       from '@croco/router';
import Util         from '@croco/util';
import DI           from '@croco/dependeny-injection';

/* ユーティリティタグ */
import binding      from '@croco-tags/utils/binding.riot';
import numberFormat from '@croco-tags/utils/number-format.riot';
import pagination   from '@croco-tags/utils/pagination.riot';
import raw          from '@croco-tags/utils/raw.riot';

const config =  {
};

/* DIコンテナ */
const DependenyInjection = new DI();

/* オブジェクト登録 */
DependenyInjection.set('riot', riot);
DependenyInjection.set('config', config);
DependenyInjection.set('util', Util.utils);
DependenyInjection.set('route', new Router());

/* タグの登録 */
DependenyInjection.riot.register('binding', binding);
DependenyInjection.riot.register('number-format-tag', numberFormat);
DependenyInjection.riot.register('pagination-tag', pagination);
DependenyInjection.riot.register('raw-tag', raw);

export default DependenyInjection;
