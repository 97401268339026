import di from 'dependeny-injection';
import container from '@tags/content-form.riot';
import Api from "@model/api.js";
di.set("createApi", () =>  new Api() );

const mtContainer = di.riot.component(container);
mtContainer(
  document.getElementById('root')
);
mtContainer(
  document.getElementById('root2')
);